import * as React from 'react'
import { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ShopWatchCategory from '../components/ShopWatchCategory'
import ShopJewelryCategory from '../components/ShopJewelryCategory'
import LatestBlogs from '../components/LatestBlogs'
import HomeNewArrivalSlider from '../components/sliders/NewArrivalSlider'
import { IconLikeNew, IconReturnPolicy, IconWarranty } from '../components/Icons'
import Testimonials from '../components/homepage/Testimonials'
import AsAdvertisedOn from '../components/AsAdvertisedOn'
import HomePageCTABoxes from '../components/HomePageCTABoxes'
import WhatWeDo from '../components/WhatWeDo'
import { IconMensWatch, IconSet } from '../components/products/IconsProductPreloader'
// import SocialMediaCard from '../components/products/SocialMediaCard'

const Index = () => {
  const slides = 3
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentSlide(cs => (cs + 1) % slides)
    }, 6000)
    return () => clearInterval(timerId)
  }, [])

  return (
    <Layout className={'overscroll-x-none'}>
      <Seo
        title="Used Luxury Jewelry and Watches | Gray & Sons Jewelers"
        description="With our selection of used jewelry and watches, find the perfect new addition for your style story. Choose from the best names on the scene."
        canonical="/"
      />
      <div className="grid w-full bg-gray-200">
        <div className="container mx-auto flex flex-col items-center justify-center lg:flex-row">
          <div className="relative w-full">
            <div
              className={
                (currentSlide === 0 ? 'opacity-100' : 'opacity-0') +
                ' transition-all duration-[2s] '
              }
            >
              <StaticImage
                alt="Preowned certified rolex, used patek philippe watches, luxury cartier love bracelet"
                src={'../images/1600-gns-site-banner-00.png'}
                loading="eager"
                placeholder="bg-gray-200"
              />
            </div>

            <div
              className={
                (currentSlide === 1 ? 'opacity-100' : 'opacity-0') +
                ' absolute top-0 transition-all duration-[2s]'
              }
            >
              <StaticImage
                alt="used audemars piguet watches offshore, cartier ballon bleu sale of diamond ring and necklace"
                src={'../images/1600-gns-site-banner-01.png'}
                loading="lazy"
                placeholder="blurred"
              />
            </div>

            <div
              className={
                (currentSlide === 2 ? 'opacity-100' : 'opacity-0') +
                ' absolute top-0 transition-all duration-[2s]'
              }
            >
              <StaticImage
                alt="omega watches for sale, used jaeger lecoultre and luxury jewelry"
                src={'../images/1600-gns-site-banner-02.png'}
                loading="lazy"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className="w-full items-center p-5 text-center grid grid-cols-2 gap-3">
            <h1 className="col-span-2 text-lg md:text-2xl xl:text-3xl font-bold text-black">
              GRAY AND SONS JEWELERS
            </h1>
            <h2 className="text-sm md:text-base col-span-2">
              THE WORLD LEADER IN USED CERTIFIED PRE- OWNED LUXURY WATCHES AND ESTATE JEWELRY | 43
              YEARS YOUNG, GREEN AND GROWING | BUY, SELL, TRADE AND REPAIR SINCE 1980 | SHOWROOM,
              MONTHLY CATALOG AND ONLINE <br />
            </h2>
            <Link
              to={'/fine-watches/'}
              className="text-sm md:text-base overflow-hidden border-2 border-black rounded-lg xs:w-44 w-full flex relative justify-center items-center p-3 duration-200 ease-in-out group indent-5 md:indent-0 md:hover:indent-5 place-self-center xs:place-self-end"
            >
              <IconMensWatch
                flashing={false}
                className="mr-2 size-6 stroke-black stroke-2 absolute left-2 md:-left-6 group-hover:left-2 duration-200 ease-in-out"
              />
              <p className="md:hidden">WATCHES</p>
              <p className="hidden md:flex">SHOP WATCHES</p>
            </Link>
            <Link
              to={'/jewelry/'}
              className="text-sm md:text-base overflow-hidden border-2 border-black rounded-lg xs:w-44 w-full flex relative justify-center items-center p-3 duration-200 ease-in-out group indent-5 md:indent-0 md:hover:indent-5 place-self-center xs:place-self-start"
            >
              <IconSet
                flashing={false}
                className="mr-2 size-6  absolute left-2 md:-left-6 group-hover:left-2 duration-200 ease-in-out"
              />
              <p className="md:hidden">JEWELRY</p>
              <p className="hidden md:flex">SHOP JEWELRY</p>
            </Link>
          </div>
        </div>
      </div>
      <WhatWeDo />
      <div className="mx-auto max-w-[1366px] w-11/12">
        <HomeNewArrivalSlider className="py-10" eager={true} />
        <hr className="" />
        <ShopWatchCategory />
        {/* <hr className="" /> */}
        {/* <SocialMediaCard /> */}
        <hr className="" />
        <ShopJewelryCategory />
        <section className="my-10 max-w-[1366px] border-t border-b py-20">
          <div className=" mx-auto lg:col-span-3">
            <h2>
              <Link className="underline-title" to="/about-us">
                About Us
              </Link>
            </h2>
            <p className="py-5 leading-loose max-w-[800px] mx-auto">
              Biggest Rolex watch inventory and pre-owned timepieces by Rolex in stock. #1 Rolex wristwatch certified
              preowned used restored in-house exclusive 2 year guarantee. Rolex Daytona,
              Rolex Sky Dweller, Rolex Datejust, Submariner, Hulk, President Day-Date Presidential
              and more. The primary focus of our company has always been selling meticulously
              restored & "Like-New for Life" Certified Preowned watches and one-of-a-kind estate
              jewelry pieces. We can also special order unused watches from most of the famous
              Swiss and Germans Watch Brands and have an impressive selection of modern jewelry as well as diamonds of
              all types & qualities. We are always acquiring new watch and jewelry inventory pieces, so
              check our website frequently, or sign up for our <Link to="/catalog-request/">free state of the art monthly catalog</Link>. 
              If you have an item that you are interested in <Link to="/sell-to-us/">selling, we can buy it outright</Link>, 
              or you have an option of consigning or trading. We can help sell your piece Our
              Master-trained watchmakers are experts at restoring watches to their original like-new
              condition. 
            </p>
            <p className="py-5 leading-loose max-w-[800px] mx-auto">
              We are one of the world famous destinations for US and Miami watch repair and watch restoration. 
              Our in-house Master-trained watchmakers with over 150 years combined
              experience stand ready to handle all your watch repairs and service needs. If you have a watch
              that is not working as it once did, send it to us. You will be amazed at your newly restored
              timepiece. Our Miami Beach showroom is located across from the world renowned Bal Harbour
              Shops, and we have been doing mail order business with customers around the country,
              and even around the world. If you are ever in the area please stop by, we would love
              to meet you.
            </p>
          </div>
          <HomePageCTABoxes />
          <h2>
            <Link className="underline-title" to="/ourstory">
              The Gray & Sons Promise
            </Link>
          </h2>

          <section className="flex flex-col md:flex-row text-left md:text-center gap-3">
            <Link
              to="/our-guarantee"
              className="group grid grid-cols-4 md:grid-cols-1 w-full items-center"
            >
              <div className="icon-warranty md:w-1/2 max-w-[200px] aspect-square mx-auto">
                <IconWarranty className="w-full aspect-square" />
              </div>
              <div className="col-span-3 px-2">
                <h3 className="w-full font-bold uppercase duration-200 group-hover:tracking-wide">
                  1-2 Year Warranty
                </h3>
                Every fine timepiece purchased at Gray & Sons, is accompanied by a Receipt of Certified Pre-Owned and Valuation Report.
              </div>
            </Link>

            <Link
              to="/our-guarantee"
              className="group grid grid-cols-4 md:grid-cols-1 w-full items-center "
            >
              <div className="icon-like-new md:w-1/2 max-w-[200px] aspect-square mx-auto">
                <IconLikeNew className="aspect-square w-full" />
              </div>
              <div className="col-span-3 px-2">
                <h3 className="font-bold uppercase duration-200 group-hover:tracking-wide">
                  "Like-New For Life" Policy
                </h3>
                Keep your fine watch looking like new for years to come.
              </div>
            </Link>

            <Link
              to="/our-guarantee"
              className="group grid grid-cols-4 md:grid-cols-1 w-full items-center"
            >
              <div className="icon-return-policy md:w-1/2 max-w-[200px] aspect-square mx-auto">
                <IconReturnPolicy className="w-full aspect-square" />
              </div>
              <div className="col-span-3 px-2">
                <h3 className="font-bold uppercase duration-200 group-hover:tracking-wide">
                  10 Day Return Policy
                </h3>
                Our 10-day money back guarantee to make sure you are happy with your purchase.
              </div>
            </Link>
          </section>
        </section>
        <LatestBlogs />

        <section className="my-10 grid max-w-[1366px] grid-cols-1 border-t border-b py-20 lg:grid-cols-3">
          <div className=" mx-auto lg:col-span-3">
            <h2>
              <Link className="underline-title" to="/repairs">
                Our Services
              </Link>
            </h2>
            <p className="py-5 leading-loose max-w-[800px] mx-auto">
              Since 1980, Gray and Sons has been dominating repairs within the luxury watch and
              jewelry industry. We specialize in all types of repairs and make the impossible
              feasible. The repairs are processed and completed in our boutique by our in-house
              master watchmakers and jewelers. Our in-house jewelers work on all sorts of jewelry
              including rings, necklaces, earrings, bracelets, and more! Gray and Sons will handle
              all types of repairs, even minor adjustments. Anything beyond the stretch of your
              imagination within the jewelry repair business, Gray and Sons will tackle your issue!
              If you have any questions, feel free to ask; we can easily break down the process and
              steps for the customer's comprehension. If you love the ring your boyfriend gifted you
              but cannot stand yellow gold and wished it would've been white gold, Gray and Sons can
              take care of you. If your bracelet doesn’t fit quite right, we can lengthen or shorten
              it. Accidents happen; if your necklace gets caught on the sweater Grandma knitted for
              you and diamonds fall out… we can replace the diamond for you! Imagine you inherited a
              two carat diamond ring but it looks too old-fashioned for you; let Gray & Sons
              transfer the diamond to a more modern setting from our vast selection. Jewelry is a
              way to express yourself, and it is often inexpensive to repair or refine a piece to
              pristine condition.
            </p>
            <section className="grid w-full max-w-[1366px] grid-cols-1 gap-4 py-20 lg:grid-cols-4">
              <Link to="/jewelry-repair/" className="overflow-hidden uppercase group">
                <h3 className=" group-hover:text-red-700 duration-300 ease-in-out text-lg py-2 font-bold">
                  Repairs
                </h3>
                <StaticImage
                  alt="Watch and Jewelry Repair"
                  src={'../images/homepage/luxury-jewelry-repair.jpg'}
                  loading="lazy"
                  className="duration-200 hover:scale-[102%]"
                />
              </Link>

              <Link to="/repairs" className="overflow-hidden uppercase group">
                <h3 className=" group-hover:text-red-700 duration-300 ease-in-out text-lg py-2 font-bold">
                  Repair Blogs
                </h3>
                <StaticImage
                  alt="Watch Repairs"
                  src={'../images/homepage/luxury-watch-repair.jpg'}
                  loading="lazy"
                  className="duration-200 hover:scale-[102%]"
                />
              </Link>

              <Link to="/ez-ship-box-request-form" className="overflow-hidden uppercase group">
                <h3 className=" group-hover:text-red-700 duration-300 ease-in-out text-lg py-2 font-bold">
                  EZ Ship Box Request
                </h3>
                <StaticImage
                  alt="Request EZ Ship Box"
                  src={'../images/homepage/ez-ship-box.jpg'}
                  loading="lazy"
                  className="duration-200 hover:scale-[102%]"
                />
              </Link>

              {/* eslint-disable-next-line */}
              <a
                href="https://sellusyourjewelry.com/"
                target={'_blank'}
                rel={'noopener'}
                className="overflow-hidden uppercase group"
              >
                <h3 className=" group-hover:text-red-700 duration-300 ease-in-out text-lg py-2 font-bold">
                  Trade / Consign
                </h3>
                <StaticImage
                  alt="Trade or Consignment"
                  src={'../images/homepage/trade-consign-luxury-watch.jpg'}
                  loading="lazy"
                  className="duration-200 hover:scale-[102%]"
                />
              </a>
            </section>
          </div>
        </section>

        <section className="my-20 w-full">
          <h2>
            <Link className="underline-title" to="/grayandsons-fanclub">
              our customers love us
            </Link>
          </h2>
          <Testimonials />
        </section>
      </div>
      <AsAdvertisedOn className="w-full px-[8.33%]" />
    </Layout>
  )
}

export default Index
